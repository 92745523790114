


































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import RequestSaveForm from '@/utils/RequestSaveForm';

import Layout from '@/layouts/LayoutDefault.vue';
import { ActionInterface } from '@/types/breadcrumb';
import { PaginatedData } from '@/types/paginatedData';
import { FiltersPaginated } from '@/types/filters';

import ProFolder from '@models/proRisk/ProFolder';
import Risk from '@models/proRisk/Risk';
import ProRiskFilter from '@modules/professional-risk/components/form/ProRiskFilter.vue';

const riskProModule = namespace('riskPro');
const riskProRepository = namespace('repositories/risk');

@Component({
  components: {
    ProRiskFilter,
    Layout,
  },
})

export default class RiskIdentificationView extends Vue {
  @riskProModule.Getter('getActiveProFolder')
  proFolder!: ProFolder | null;

  @riskProRepository.Getter('getAllOrder')
  risksOrder!: (order: { col: string, direction: 'asc' | 'desc' }) => Risk[];

  @riskProRepository.Action('deleteAll')
  deleteRisks!: () => Promise<void>;

  @riskProRepository.Action('search')
  fetchRisks!: (payload: { folder: ProFolder, filters: any }) => Promise<PaginatedData<Risk>>;

  @riskProRepository.Action('delete')
  delete!: (id: number) => Promise<void>;

  @riskProModule.Action('duplicate')
  duplicate!: (id: number) => Promise<void>;

  risks: Risk[] = [];
  loading: boolean = false;
  totalResult = 0;

  tableProps: any = {
    border: true,
    stripe: true,
    defaultSort: { prop: 'position', order: 'ascending' },
  };

  paginationProps: any = {
    pageSizes: [10, 20, 50, 100],
    layout: 'prev, pager, next, jumper, ->, total, slot',
  };

  filters: FiltersPaginated = {
    riskCharacter: '',
    job: '',
    unit: '',
    limit: 10,
    page: 1,
    order: '',
    orderBy: '',
  };

  get actions(): ActionInterface[] {
    return this.isGranted('pro-risks-identification', 2) ? [
      {
        label: 'Ajouter',
        route: 'pro-risks-identification-create',
      },
    ] : [];
  }

  @Watch('filters', { immediate: true, deep: true })
  onFilterChange() {
    if (!this.loading) {
      this.load();
    }
  }

  async load() {
    if (this.proFolder) {
      this.loading = true;
      const data = await this.fetchRisks({ folder: this.proFolder, filters: this.filters });
      this.totalResult = data.meta.total;
      const direction: 'asc' | 'desc' = this.filters.order.toLowerCase() === 'asc' ? 'asc' : 'desc';
      this.risks = this.risksOrder({ col: this.filters.orderBy as string, direction });
      this.loading = false;
    }
  }

  sortChange(payload: { type: string, sort: any }) {
    const { type, sort } = payload;
    if (type !== 'sort') {
      return;
    }

    if (sort.order !== null) {
      this.filters.order = sort.order === 'ascending' ? 'ASC' : 'DESC';
      this.filters.orderBy = sort.prop;
    } else {
      this.filters.order = '';
      this.filters.orderBy = '';
    }
  }

  handleDeleteBtn(id: number) {
    this.$confirm('Confirmer la suppression du risque.').then(async (result) => {
      if (result === 'confirm') {
        this.loading = true;
        this.handleDelete(id)
          .then(async (response) => {
            await this.load();
            return response;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    });
  }

  handleDuplicateBtn(id: number) {
    this.$confirm('Confirmer la duplication du risque.').then(async (result) => {
      if (result === 'confirm') {
        this.loading = true;
        this.handleDuplicate(id)
          .then(async (response) => {
            await this.load();
            return response;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    });
  }

  @RequestSaveForm({
    validMsg: 'Suppression effectuée.',
    errorMsg: 'Erreur lors de la suppression de votre risque.',
    duration: 10000,
  })
  handleDelete(id: number) {
    return this.delete(id);
  }

  handleDuplicate(id: number) {
    return this.duplicate(id);
  }

  beforeDestroy() {
    this.deleteRisks();
  }
}
