



























import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import FilterComponent from '@/components/form/FilterComponent';
import StructureFilter from '@/components/form/StructureFilter.vue';

import RiskCharacter from '@models/proRisk/RiskCharacter';

const riskCharacterRepository = namespace('repositories/riskCharacter');

@Component({
  components: { StructureFilter },
})
export default class ProRiskFilter extends FilterComponent {
  @riskCharacterRepository.Getter('getAll')
  riskCharacters!: RiskCharacter[];

  filters = {
    riskCharacter: '',
    affectation: '',
  };
}
